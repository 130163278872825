.nav-tabs {
  .nav-link {
    border-radius: 0.5em 0.5em 0 0;
    font-size: 1.25em;
    &.active {
      background: #fcfdfe;
      border-bottom-color: #fcfdfe;
      &:focus {
        background: #fcfdfe;
        border-bottom-color: #fcfdfe;
      }
    }
  }
}
.nav-link {
  // Disabled state lightens text and set important
  &.disabled {
    color: $nav-link-disabled-color !important;
    pointer-events: none;
    cursor: default;
    .badge {
      background-color: $nav-link-disabled-color !important;
    }
  }
}



.tab-navbar {
  .tab-content {
    border: 0;
    border-top: 1px solid #a4b7c1 !important;
  }
  .tab-brand {
    font-size: 18px;
    margin-top: -8px;
    margin-right: 0;
    border-bottom: 1px solid #FFF;
  }

  .tab-buttons {
    font-size: 18px;
    margin-top: 0;
    margin-right: 0;
    border-bottom: 1px solid #FFF;
  }

}

.nav.nav-tabs.nav-tabs-full {
  display: flex;
  width: 100%;
  table-layout: fixed;
  > li {
    float: none;
    display: inline-block;
    > a {
      text-align: center;
    }
  }
}
