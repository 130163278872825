
/*
 * Toast messages styles override
 */
.universis-toast-container .toast {
  border: 0;
}
.universis-toast-container.hide-this-please {
  display: none;
}
.universis-toast-container {
  position: fixed;
  top: 66px;
  right: 45px;
  background-color: #ffffff;
  border: 0;
  border-radius: 4px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  width: 250px;
}
.universis-toast-container .toast-body {
  width: 100%;
}
.toast-header,
.toast.show,
.toast-body {
  border: 0;
  box-shadow: none;
  display: inline-block;
  color: #29363d;
  font-size: 14px;
}
.toast-body .toast-body-content{
  width: 90%;
  float: left;
}
.toast.show {
   position: relative;
 }
.toast-success .toast-header,
.toast-success .toast.show,
.toast-success .toast-body{
  background-color: #ffffff;
  color : #4dbd74;
}
.toast-error .toast.show,
.toast-error .toast-header,
.toast-error .toast-body {
  background-color: #ffffff;
  color : #f86c6b;
}
.universis-toast-container .toast-body-close {
  float: right;
}
.universis-toast-container button.close {
  color: #678898;
}
.universis-toast-container .toast-date {
  color: rgba(129, 135, 142, 0.62);
  font-size: 12px;
}
/*
 * END Toast messages styles override
 */
