.card {
  border-radius: 6px;
  border: none;
  box-shadow: 0 0 17px 0 rgba(177,177,177,.5);
}

.card-header {
  background-color: transparent;
  border: none;
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: $card-spacer-x;
}

.card-footer {
  background-color: transparent;
  border-top-color: #dee2e4 !important;
  border-top-width: 0px;
}
