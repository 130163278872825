.app-header.navbar {
  box-shadow: 0 0 17px 0 rgba(177,177,177,0.5);
  border: none;

  .navbar-nav .dropdown-menu{
    overflow: hidden;
  }

  .sis-brand {
    color: #2500dd;
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.17px;
    .sis-brand-bold {
      font-weight: 400;
    }
  }
}

.s--list-navbar {
  display: flex;
  flex-wrap: wrap;
  padding-top: auto;
  padding-bottom: auto;
  border: 1px solid #C2CFD6;
  border-radius: 6px;
  background-color: #F0F3F5;
}
