
/*
 * Formio checkbox and radio buttons reset
 */
.formio-form .formio-component .radio,
.formio-form .formio-component .checkbox {
  display: block;
}
/*
 * Formio disabled fields lighter gray color
 */
.formio-form .formio-component .form-control.form-control:disabled,
.formio-form .formio-component .form-control.form-control[readonly] {
  background-color:  theme-color('gray-100');
}
/*
 * Formio disabled fields lighter gray color
 */
.formio-form .formio-component .nav-tabs {
  margin-top: auto;
  border-bottom: 1px solid theme-color('gray-300');
}
.formio-form .formio-component .nav-link {
  padding: 0.5em 1em;
  font-size: 1em;
  border: 1px solid transparent;
}
.formio-form .formio-component .nav-link.active {
  border: 1px solid theme-color('gray-300');
  border-bottom: 1px solid transparent;
}
.formio-form .formio-component .nav-link:hover {
  border: 1px solid theme-color('gray-300');
  border-bottom: 1px solid transparent;
}
.formio-form .formio-component .nav-link {
  padding: 0.5em 1em;
}
.formio-component.formio-component-tabs>.card {
  padding: 0!important;
  background: none!important;
  box-shadow: none;
}
.formio-component.formio-component-tabs>.card>.card-header{
  padding: 0;
}
.formio-component.formio-component-tabs>.card>.card-header>.nav.nav-tabs.card-header-tabs {
  margin: 0;
  font-size: 1rem;
}

app-advanced-form-router div.card div.card-body {
  padding: 2rem 1rem !important;
}
