.grad-rule {
  display: grid;
  grid-template-columns: minmax(1em, 2.5em) 13fr;
  grid-template-rows: minmax(2em, max-content) minmax(2em, max-content) minmax(min-content, max-content);
  grid-template-areas:
    "icon-container  title"
    "icon-container  description"
    "icon-container  .";

  margin-top: -9px;

  &:last-child .icon-container {
    border-left: 0px;
    padding-bottom: 0px;
  }

  .title{
    grid-area: title;
    font-size: 1em;
    text-transform: uppercase;
    color: theme-color('dark-gray');
  }

  .description{
    grid-area: description;
    color: theme-color('secondary');
  }

  .icon-container{
    grid-area: icon-container;
    text-align: center;
    padding-bottom: 60px;
    border-left: 2px solid theme-color('gray-300');
    color: theme-color('secondary');

    .fa-stack {
      position: relative;
      left: -17px;
      top: -1px;
      font-size: 10px;
    }

  }
}

.grad-rule-op {
  &.and {
    display: grid;
    grid-template-columns: minmax(1em, 2.5em) minmax(1em, 2.5em) 12fr;
    grid-template-rows: minmax(min-content, max-content);
    grid-template-areas:
    "branch split grad-rule-1"
    "con    split operator-container"
    "con     .    grad-rule-2"
    "con     .    .";
  }

  &.or {
    display: grid;
    grid-template-columns: minmax(1em, 2.5em) minmax(1em, 2.5em) 12fr;
    grid-template-rows: minmax(min-content, max-content);
    grid-template-areas:
      "split split grad-rule-1"
      "split split operator-container"
      "con   .     grad-rule-2"
      "con   .     .";
  }

  .icon-container {
    padding: 0px;
    border: 0px;
  }

  .branch {
    grid-area: branch;
    border: 2px solid theme-color('gray-300');
    border-top: 0;
    border-right: 0;
  }

  .split {
    grid-area: split;
    border: 2px solid theme-color('gray-300');
    border-right: 0;
  }

  .grad-rule-1 {
    grid-area: grad-rule-1;
  }

  .grad-rule-2 {
    grid-area: grad-rule-2;
    padding-bottom: 20px;
  }

  .operator-container {
    grid-area: operator-container;
    display: grid;
    grid-template-columns: 2.5em 13fr;
    padding-bottom: 1em;

    .operator {
      padding-top: 0.5em;
      padding-bottom: 0.5em;
      grid-column-start: 2;

      * {
        text-transform: uppercase;
        background-color: theme-color('light');
        border-radius: 1em;
        padding: 0.25em 0.75em;
      }
    }
  }

  .con {
    grid-area: con;
    border-left: 2px solid theme-color('gray-300');
  }

  &:last-of-type .con {
    border-left: 0px;
  }

}
