.icon-circle {
  display: inline-block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border-style: solid;
  border-width: 16px;
  .fa {
    margin-top: 10px;
    font-size: 2em;
    color: #FFF;
  }
}

@each $color, $value in $theme-colors {
  .icon-circle-#{$color} {
    background-color: $value;
    border-color: lighten($value, 25%);
  }
}
