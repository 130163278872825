/* TOOLTIP */
.tooltip {
  background-color: #fff;
  border-radius: 6px;
  border: none;
  box-shadow: 0 0 17px 0 rgba(177, 177, 177, 0.5);
  padding: 10px 0px 10px 0px;
  opacity: 1 !important;
}

.bs-tooltip-top{
  margin-top: -10px;
}

.bs-tooltip-top .arrow::before{
  border-top-color: #fff !important;
  top: 6px !important;
}

.bs-tooltip-bottom{
  margin-top: 10px;
}

.bs-tooltip-bottom .arrow::before{
  border-bottom-color: #fff !important;
  bottom: 6px !important;
}

.tooltip-inner {
  background-color: #fff;
  color: #536C79;
  max-width: 426px !important;
  width: 426px !important;
}

.tooltip-header {
  text-align: center;
  color: #A4B7C1;
  margin-bottom: 10px;
}

.tooltip-body {
  text-align: left;
}

.tooltip-big-text {
  font-size: 18px;
}

.tooltip-body-item {
  display: block;
  font-size: 14px;
}

.tooltip-small-text {
  font-size: 11px;
  color: #A4B7C1;
}

.tooltip-passed-grade {
  color: #19D65B;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.76px;
  text-align: right;
}
