$title-font-size : 31px;

$title-line-height: $title-font-size + 10px;

$card-spacer-y:   .75rem;
$card-spacer-x:   2rem;

$place-holder-color: #a4b7c1;

$color-theme: #2500dd;
