@import "./bootstrap/variables";
@import "./coreui/variables";
@import "./variables";

.modal-content-grades {
  .text-subtitle {
    color: #536C79;
  }

  .success-color {
    color: #4dbd74;
  }

  .unsuccess-color {
    color: #F86C6B;
  }

  .fs20 {
    font-size: 20px;
  }

  .nav-tabs .nav-link:hover {
    border-color: transparent;
  }

  .nav-tabs .nav-link {
    border-color: transparent;
  }

  .nav-tabs {
    border-bottom: 0;
  }

  .nav-tabs.step-anchor li.nav-item {
    display: none;
  }

  .nav-tabs.step-anchor .nav-item.active {
    display: block !important;
  }

  .tab-content {
    border: none;
  }

  .btn-toolbar {
    justify-content: center !important;
  }

  .btn-toolbar .btn {
    margin: 0px 20px 0 20px;
  }

  .sw-btn-prev {
    background-color: #fff;
    color: #333 !important;
    border-radius: 4px !important;
    border-color: #333;
  }

  .sw-btn-next {
    background-color: #4dbd74;
    border-radius: 4px !important;
    border-color: #4dbd74;
  }

  .btn-close {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  @media (max-width: 767px) {
    .sw-toolbar-bottom {
      margin-left: 0.5rem;
    }

    .sw-toolbar-bottom .btn {
      display: block;
      width: 100%;
      margin: 0 0 1em 0;
    }

    .sw-toolbar-bottom .btn-group {
      display: block;
      width: 93%;
    }

  }
}
.modal-header {
  border-top: 0;
  border-bottom: 0;
}
.modal-footer {
  border-top: 0;
  border-bottom: 0;
}

.modal-content {
  border-radius: 6px;
  border: none;
  box-shadow: 0 0 17px 0 rgba(177,177,177,.5);
}

.modal-dialog {
  margin-top: 3.5rem;
  .modal-content {
    padding: 1.5rem;
  }
  .modal-body {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .modal-header {
    margin-bottom: 0;
    padding-bottom: 0;
    .modal-title {
      margin-bottom: 0;
      width: 100%;
    }
    button.close {
      position: relative;
      top: -5rem;
      right: -2.5rem;
      color: #FFF;
      font-size: 2rem;
      outline-style: none;
      opacity: 1;
    }
    h1 {
      margin-right: -48px;
    }
  }
  .modal-footer {
    justify-content: center;
    button {
      min-width: 7rem;
    }
    > :not(:last-child) {
      margin-right: .75rem;
    }
  }
}

@each $color, $value in $theme-colors {
  .modal-dialog-#{$color} {
    .btn-theme {
      @include button-variant($value, $value);
    }
  }
}

/* modal table dialog */

.modal-table.modal-dialog {

  .modal-header {
    .modal-title {
      font-size: 20px;
    }
  }

  .modal-content {
    .component-loading {
      .dataTables_wrapper {
        opacity: 0.6;
      }
    }

    .icon-circle {
      display: inline-block;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border-style: solid;
      border-width: 16px;
    }

    .dataTables_empty {
      border-top: none !important;
      border-bottom: none !important;
    }

    .dataTables_wrapper {
      // border-top: solid 2px $gray-200;
      margin-left: -40px;
      margin-right: -40px;
    }
    table.dataTable {
      thead {
        tr {
          //th.sorting_asc:before {
          //  font-family: 'Font Awesome 5 Free';
          //  content: "\f30c";
          //  padding-right: 4px;
          //}
          //th.sorting_desc:before {
          //  font-family: 'Font Awesome 5 Free';
          //  content: "\f309";
          //  padding-right: 4px;
          //}
        }
      }
      tbody{
        tr{
          td {
            color: theme-color('secondary');
          }
          td:first-child {
            border-left: none;
            border-radius: unset;
            padding-left: 64px;
          }
          td:last-child {
            border-right: none;
            border-radius: unset;
            padding-right: 64px;
          }
        }
      }
    }
    .dataTables_paginate.paging_first_last_numbers {
      .btn.first {
        margin-right: 8px;
      }
      .btn.last {
        margin-left: 8px;
      }
    }
    .table-custom-pager {
      .btn:focus, .btn.focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(37, 0, 221,0.25);
      }
      .btn.disabled, .btn:disabled {
        cursor: default;
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }

    .dropdown-item.dropdown-item-theme.active, .dropdown-item.dropdown-item-theme:active {
      background-color: lighten($color-theme, 30%);
    }

  }


}
