@import "./bootstrap/variables";
.app-header {
  .navbar-nav {
    .dropdown-menu {
      border-radius: 5px;
      background-color: #FFFFFF;
      box-shadow: 0 0 17px 0 rgba(177,177,177,0.5);
      border: 0;
    }
  }
}
.dropdown-menu {
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 0 17px 0 rgba(177,177,177,0.5);
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  outline: none;
}
