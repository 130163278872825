.radio {
	position: absolute;
	display: none;
	&+label {
		position: relative;
		display: block;
    padding-left: 30px;
    margin-bottom: 0px;
		cursor: pointer;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 20px;
			height: 20px;
			content: '';
			border: 1px solid #c0c0c0;
			border-radius: 50%;
		}
		&:after {
			position: absolute;
			display: none;
			content: '';
			top: 6px;
			left: 6px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: theme-color('gray-900');
		}
		&:hover {
			&:before {
				border-color: theme-color('gray-500');
      }
		}
  }
  &:disabled{
    &+label{
      color: theme-color('secondary');
      &:before{
        border-color: lighten(#c0c0c0, 10% );
      }
      &:hover {
        &:before {
          border-color: lighten(#c0c0c0, 10% );
        }
      }
    }
  }
	&:checked {
		&+label {
			&:after {
				display: block;
			}
    }
  }
  &:checked:disabled {
		&+label {
      &:after {
				background: lighten(theme-color('gray-900'), 80% );
      }
      &:before {
        border-color: lighten(#c0c0c0, 10% );
      }
		}
	}
}

.checkbox {
	position: absolute;
	display: none;
	&+label {
		position: relative;
		display: block;
    padding-left: 30px;
    margin-bottom: 0px;
		cursor: pointer;
		&:before {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 20px;
			height: 20px;
			content: '';
			border: 1px solid #c0c0c0;
			border-radius: 3px;
		}
		&:after {
			position: absolute;
			display: none;
			content: '';
			top: 2px;
			left: 7px;
			box-sizing: border-box;
			width: 6px;
			height: 12px;
			transform: rotate(45deg);
			border-width: 2px;
			border-style: solid;
			border-top: 0;
			border-left: 0;
		}
		&:hover {
			&:before {
				border-color: theme-color('gray-500');
			}
		}
  }
  &:disabled{
    &+label{
      color: theme-color('secondary');
      &:before {
        border-color: lighten(#c0c0c0, 10% );
      }
    }
  }
	&:checked {
		&+label {
			&:after {
				display: block;
			}
		}
	}
	&:checked:disabled {
		&+label {
      &:after{
        border-color: lighten(theme-color('dark'), 40% );
      }
    }
  }
}
.checkbox-primary {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('primary');
			}
    }
    &:after {
      border-color: theme-color('light');
    }
	}
	&:checked {
		&+label {
			&:before {
				border: theme-color('primary');
				background: theme-color('primary');
			}
		}
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten(theme-color('primary'), 40% );
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:hover {
        &:before {
          border-color: lighten(theme-color('primary'), 40% );
        }
      }
			&:before {
				background: lighten(theme-color('primary'), 40% );
      }
      &:after {
        border-color: white;
      }
		}
	}
}
.checkbox-secondary {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('secondary');
			}
    }
    &:after {
      border-color: theme-color('light');
    }
	}
	&:checked {
		&+label {
			&:before {
				border: theme-color('secondary');
				background: theme-color('secondary');
			}
		}
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten(theme-color('secondary'), 40% );
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:hover {
        &:before {
          border-color: lighten(theme-color('secondary'), 40% );
        }
      }
			&:before {
				background: lighten(theme-color('secondary'), 40% );
      }
      &:after {
        border-color: white;
      }
		}
	}
}
.checkbox-success {
  &+label {
    &:hover {
      &:before {
        border-color: theme-color('success');
      }
    }
    &:after {
      border-color: theme-color('light');
    }
  }
  &:checked {
    &+label {
      &:before {
        border: theme-color('success');
        background: theme-color('success');
      }
    }
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten(theme-color('success'), 35% );
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:hover {
        &:before {
          border-color: lighten(theme-color('success'), 35% );
        }
      }
			&:before {
				background: lighten(theme-color('success'), 35% );
      }
      &:after {
        border-color: white;
      }
		}
	}
}
.checkbox-info {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('info');
			}
    }
    &:after {
      border-color: theme-color('light');
    }
	}
	&:checked {
		&+label {
			&:before {
				border: theme-color('info');
				background: theme-color('info');
			}
		}
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten(theme-color('info'), 25% );
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:hover {
        &:before {
          border-color: lighten(theme-color('info'), 25% );
        }
      }
			&:before {
				background: lighten(theme-color('info'), 25% );
      }
      &:after {
        border-color: white;
      }
		}
	}
}
.checkbox-warning {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('warning');
			}
    }
    &:after {
      border-color: theme-color('light');
    }
	}
	&:checked {
		&+label {
			&:before {
				border: theme-color('warning');
				background: theme-color('warning');
			}
		}
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten(theme-color('warning'), 25% );
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:hover {
        &:before {
          border-color: lighten(theme-color('warning'), 25% );
        }
      }
			&:before {
				background: lighten(theme-color('warning'), 25% );
      }
      &:after {
        border-color: white;
      }
		}
	}
}
.checkbox-danger {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('danger');
			}
    }
    &:after {
      border-color: theme-color('light');
    }
	}
	&:checked {
		&+label {
			&:before {
				border: theme-color('danger');
				background: theme-color('danger');
			}
		}
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten(theme-color('danger'), 20% );
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:hover {
        &:before {
          border-color: lighten(theme-color('danger'), 20% );
        }
      }
			&:before {
				background: lighten(theme-color('danger'), 20% );
      }
      &:after {
        border-color: white;
      }
		}
	}
}
.checkbox-light {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('gray-500');
			}
    }
    &:after {
			border-color: theme-color('dark');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: theme-color('light');
				background: theme-color('light');
			}
		}
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten(#c0c0c0, 20% );
      }
    }
  }
  &:checked:disabled {
		&+label {
			&:before {
				background: lighten(theme-color('light'), 40% );
      }
      &:after{
        border-color: lighten(theme-color('dark'), 40% );
      }
		}
	}
}
.checkbox-dark {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('dark');
			}
    }
    &:after {
      border-color: theme-color('light');
    }
	}
	&:checked {
		&+label {
			&:before {
				border: theme-color('dark');
				background: theme-color('dark');
			}
		}
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten(theme-color('dark'), 65% );
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:hover {
        &:before {
          border-color: lighten(theme-color('dark'), 65% );
        }
      }
			&:before {
				background: lighten(theme-color('dark'), 40% );
      }
      &:after {
        border-color: white;
      }
		}
	}
}

.radio-primary {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('primary');
			}
		}
		&:after {
			background: theme-color('primary');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: 1px solid theme-color('primary');
			}
		}
  }
  &:disabled {
    &+label {
      &:before {
        border-color: lighten(theme-color('primary'), 40% );
      }
      &:hover {
        &:before {
          border-color: lighten(theme-color('primary'), 40% )
        }
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:before {
        border-color: lighten(theme-color('primary'), 40% );
      }
      &:after {
				background: lighten(theme-color('primary'), 40% );
      }
		}
	}
}
.radio-secondary {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('secondary');
			}
		}
		&:after {
			background: theme-color('secondary');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: 1px solid theme-color('secondary');
			}
		}
  }
  &:disabled {
    &+label {
      &:before {
        border-color: lighten(theme-color('secondary'), 40% );
      }
      &:hover {
        &:before {
          border-color: lighten(theme-color('secondary'), 40% )
        }
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:before {
        border-color: lighten(theme-color('secondary'), 40% );
      }
      &:after {
				background: lighten(theme-color('secondary'), 40% );
      }
		}
	}
}
.radio-success {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('success');
			}
		}
		&:after {
			background: theme-color('success');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: 1px solid theme-color('success');
			}
		}
  }
  &:disabled {
    &+label {
      &:before {
        border-color: lighten(theme-color('success'), 35% );
      }
      &:hover {
        &:before {
          border-color: lighten(theme-color('success'), 35% )
        }
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:before {
        border-color: lighten(theme-color('success'), 35% );
      }
      &:after {
				background: lighten(theme-color('success'), 35% );
      }
		}
	}
}
.radio-info {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('info');
			}
		}
		&:after {
			background: theme-color('info');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: 1px solid theme-color('info');
			}
		}
  }
  &:disabled {
    &+label {
      &:before {
        border-color: lighten(theme-color('info'), 25% );
      }
      &:hover {
        &:before {
          border-color: lighten(theme-color('info'), 25% )
        }
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:before {
        border-color: lighten(theme-color('info'), 25% );
      }
      &:after {
				background: lighten(theme-color('info'), 25% );
      }
		}
	}
}
.radio-warning {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('warning');
			}
		}
		&:after {
			background: theme-color('warning');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: 1px solid theme-color('warning');
			}
		}
  }
  &:disabled {
    &+label {
      &:before {
        border-color: lighten(theme-color('warning'), 25% );
      }
      &:hover {
        &:before {
          border-color: lighten(theme-color('warning'), 25% )
        }
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:before {
        border-color: lighten(theme-color('warning'), 25% );
      }
      &:after {
				background: lighten(theme-color('warning'), 25% );
      }
		}
	}
}
.radio-danger {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('danger');
			}
		}
		&:after {
			background: theme-color('danger');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: 1px solid theme-color('danger');
			}
		}
  }
  &:disabled {
    &+label {
      &:before {
        border-color: lighten(theme-color('danger'), 20% );
      }
      &:hover {
        &:before {
          border-color: lighten(theme-color('danger'), 20% )
        }
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:before {
        border-color: lighten(theme-color('danger'), 20% );
      }
      &:after {
				background: lighten(theme-color('danger'), 20% );
      }
		}
	}
}
.radio-light {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('light');
			}
		}
		&:after {
			background: theme-color('light');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: 1px solid theme-color('light');
			}
		}
  }
  &:disabled {
    &+label {
      &:before {
        border-color: lighten(theme-color('light'), 0.4);
      }
      &:hover {
        &:before {
          border-color: lighten(theme-color('light'), 0.4);
        }
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:before {
        border-color: lighten(theme-color('light'), 0.4);
      }
      &:after {
				background: lighten(theme-color('light'), 0.4);
      }
		}
	}
}
.radio-dark {
	&+label {
		&:hover {
			&:before {
				border-color: theme-color('dark');
			}
		}
		&:after {
			background: theme-color('dark');
		}
	}
	&:checked {
		&+label {
			&:before {
				border: 1px solid theme-color('dark');
			}
		}
  }
  &:disabled {
    &+label {
      &:before {
        border-color: lighten(theme-color('dark'), 65% );
      }
      &:hover {
        &:before {
          border-color: lighten(theme-color('dark'), 65% )
        }
      }
    }
  }
  &:checked:disabled {
		&+label {
      &:before {
        border-color: lighten(theme-color('dark'), 65% );
      }
      &:after {
				background: lighten(theme-color('dark'), 65% );
      }
		}
	}
}

.checkbox-theme {
  &+label {
    &:hover {
      &:before {
        border-color: $color-theme;
      }
    }
    &:after {
      border-color: theme-color('light');
    }
  }
  &:checked {
    &+label {
      &:before {
        border: $color-theme;
        background: $color-theme;
      }
    }
  }
  &:disabled{
    &+label{
      &:before {
        border-color: lighten($color-theme, 40% );
      }
    }
  }
  &:checked:disabled {
    &+label {
      &:hover {
        &:before {
          border-color: lighten($color-theme, 40% );
        }
      }
      &:before {
        background: lighten($color-theme, 40% );
      }
      &:after {
        border-color: white;
      }
    }
  }
}
