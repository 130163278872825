textarea {
  resize: none;
}

.custom-file-input {
  cursor: pointer;
}

.custom-file-label{
  color: $place-holder-color;
}

.custom-file-input:focus ~ .custom-file-label {
  outline: none;
  box-shadow: none;
}

.custom-file-label::after {
  background-color: #869FAC !important;
  color: #fff;
  margin: .25rem;
  height: 1.5rem;
  padding: 0 1.5rem 0 1.5rem;
  line-height: 1.5rem;
  border-radius: .25rem;
  cursor: pointer;
}

input::placeholder,
textarea::placeholder {
  color: $place-holder-color !important;
  opacity: 1; /* Firefox */
}
