.sis--list-title {
  font-size: 17.5px;
  color: #29363D;
  margin-bottom: 20px;
}

.sis--list-group-header {
  font-size:12px;
  color:#869FAC;
  border-style: none;

  .sis--list-group-item {
    font-weight: 600;
    padding-bottom: 6px;
    background-color:transparent;
    border: none;
  }

}

.sis--list-group-body {
  .sis--list-group-item:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .sis--list-group-item:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}


.sis--list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(41, 54, 61, 0.125);
}

.sis--list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}


.sis--list-group-item_sm_column {
  padding-left:5px;
  padding-right:5px;
  flex-shrink:12;
  flex-grow:1;
  flex-basis:40px;
  text-align:center;
 }

 .sis--list-group-item_lg_column {
  padding-left:5px;
  padding-right:5px;
  flex-shrink:1;
  flex-grow:4;
  flex-basis:130px;
 }

 .sis--list-group-item_md_column {
  padding-left:5px;
  padding-right:5px;
  flex-shrink:4;
  flex-grow:3;
  flex-basis:80px;
  text-align:center;
 }



