.avatar-male-student {
  background: url("./assets/img/male-student.svg") no-repeat center;
}
.avatar-female-student {
  background: url("./assets/img/female-student.svg") no-repeat center;
}
.avatar-male-teacher {
  background: url("./assets/img/male-teacher.svg") no-repeat center;
}
.avatar-female-teacher {
  background: url("./assets/img/female-teacher.svg") no-repeat center;
}
.avatar {
  background-size:cover
}
.avatar-6 {
  width: 6em;
  height: 6em;
}
.avatar-8 {
  width: 8em;
  height: 8em;
}
.avatar-10 {
  width: 10em;
  height: 10em;
}
.avatar-12 {
  width: 12em;
  height: 12em;
}

