.text-dots {
  margin-left: 4px;
  &:after {
    content: '\2807';
    font-weight: 700;
    color: #869FAC;
  }
}

// overrides typography of coreUI

.font-xs {
  font-size: 0.75rem !important; // 12px
}

.font-sm {
  font-size: 0.875rem !important; // 14px
}

.font-lg {
  font-size: 1.0625rem !important; // 17px
}

.font-xl {
  font-size: 1.375rem !important; // 22px
}

.font-2xl {
  font-size: 1.5rem !important; // 24px
}

.font-3xl {
  font-size: 1.75rem !important; // 28px
}

.font-4xl {
  font-size: 1.9375rem !important; // 31px
}

.font-5xl {
  font-size: 2.5rem !important; // 40px
}
