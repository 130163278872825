/*

Step-slide component is the slide bar with fixed stop points.

variants:
 - .step-slider__item--active The selected one
 - .step-slider__item--pending A bullet when waiting for action from another user (i.e. registrar)
 - .step-slider__item--unavailable The "disabled" grey variant

*/

%step-slider-active-item-content {
  // Required in multiple places that normal extend can not be applied.
  font-size: 24px;
  line-height: 24px;

  .fa-stack {
    width: 24px;
    height: 24px;

    &:before {
      border-width: 3px;
    }
  }

  .step-slider__item-text {
    color: $black;
  }
}

.step-slider {
  list-style: none;
  margin: 0;
  padding: 0;

  &__item {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 100px;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: 6px;
      z-index: -1;
      bottom: 15px; // element height
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $indigo;
    }

    &:first-child {
      padding-top: 0;

      &::after{
        content: none;
      }
    }

    &--active {
      .step-slider__item-content {
        @extend %step-slider-active-item-content;
      }
    }

    &--pending {
      .step-slider__item-content .fa-stack {
        &::before {
          content: none;
        }
      }
    }

    &--unavailable {
      &::after {
        background-color: $gray-100;
      }

      .step-slider__item-content .fa-stack {
        &::before {
          background-color: $gray-200;
        }
      }
    }
  }

  &__item-content {
    font-size: 15px;
    line-height: 15px;
    width: 200px;
    position: relative;
    display: inline-block;

    .fa-stack {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background-color: $white;
      box-shadow: 0 2px 4px 0 $gray-400;

      &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: $indigo;
        border-radius: 100%;
        border: 2px solid $white;
      }

      &--active {
        @extend %step-slider-active-item-content;
      }
    }

    .fa {
      font-size: .8em;
      line-height: .8em;

    }

    .fa-stack-1x {
      font-size: .6em;
      line-height: .6em;
    }
  }

  &__item-button {
    background: transparent;
    border: none;
    padding: 0;

    &:focus,
    &:active {
      padding: 0;
    }
  }

  &__item-link {
    @extend .text-secondary;
  }

  &__item-icon-wrapper {
    display: flex;
    justify-content: center;
  }

  &__item-text {
    @extend .font-sm;
    position: absolute;
    display: flex;
    align-items: center;
    padding-left: 80px;
    top: 50%;
    transform: translateY(-50%);
    min-width: 200px;
    min-height: 40px; // Make clicking comfortable
  }
}
